<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="3"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 border-bottom col-12 d-flex justify-content-between">
              <h6>推荐的土地</h6>
              <router-link class="text-primary" to="/user/land/add" target="_blank">
                <img src="@/assets/icon/fbtd.png" style="width: 24px;"/>
                <span class="ml-1">发布需求登记</span>
              </router-link>
            </div>
            <div class="col-12">
              <div class="clearfix mt-4 d-flex align-items-center">
                <div class="float-left">
                  更新时间：
                </div>
                <div class="float-left ml-2">
                  <b-form-input type="date"></b-form-input>
                </div>
                <div class="float-left ml-2 mr-2">
                 至
                </div>
                <div class="float-left">
                  <b-form-input type="date"></b-form-input>
                </div>
                <div class="float-left ml-3">
                  <a class="text-theme" href="javascript:;">近一月</a>
                  <a class="ml-2" href="javascript:;">近三月</a>
                  <a class="ml-2" href="javascript:;">近半年</a>
                  <a class="ml-2" href="javascript:;">近一年</a>
                </div>
              </div>
              <div class="clearfix mt-2 d-flex align-items-center">
                <div class="float-left">
                  流转类型：
                </div>
                <div class="float-left ml-4">
                  <div class="row">
                    <a href="javascript:;" class="mr-3 px-2 py-1 active">全部</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">转包</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">合作</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">转让</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">互换</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">入股</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">出租</a>
                    <a href="javascript:;" class="mr-3 px-2 py-1">招拍挂</a>
                  </div>
                </div>
              </div>

             
            </div>
            <div class="col-12 mt-4 mb-4">
              <div class="card border-0">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-centered border mb-0">
                      <thead class="table-light">
                        <tr>
                          <th class="w-65">土地信息</th>
                          <th>更新时间</th>
                          <th>状态</th>
                          <th style="width: 80px">操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in 10" :key="i">
                          <td>
                            <div class="clearfix">
                              <div class="float-left">
                                <img src="https://img2s.tuliu.com/lands/2021/07/11/e0e6442b-6d87-4277-8a48-81cd3d8cdea2.jpg?x-oss-process=style/center_mid" class="width-80 rounded"/>
                              </div>
                              <div class="float-left ml-3">
                                  <h6>呼伦贝尔额尔古纳市 9400亩 旱地 转让</h6>
                                  <div class="mt-2 text-gray-777">
                                    <span>价格：1900万</span>
                                    <span class="ml-2">年限：50年</span>
                                  </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="mt-1">2021-07-31 10:29</div>
                          </td>

                          <td>
                            <h5>
                              <span class="badge badge-danger f-12"
                                >待交易</span
                              >
                            </h5>
                          </td>
                          <td>
                            <div class="mt-1 mb-1 f-16">
                              <a href="javascript:void(0);" class="ml-2">
                                <b-icon icon="trash"></b-icon>  
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div>
                      <b-pagination class="mt-1 mb-1" :total-rows="10" :per-page="1"
                          aria-controls="my-table"></b-pagination>
                    </div>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  name: "pushland",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
.form-control {
  height:31px;
}
.active {
  background: #00cc00;
  color: #ffffff;
  border-radius: 6px;
}
</style>
